import {SceneKeys, TextureKeys, SpriteKeys, MusicKeys, SoundKeys} from "../const/keys";
import {VisualLoader} from "../gameObjects/VisualLoader";
import {Game} from "../game";
import Jukebox from "../gameObjects/Jukebox";


export default class PreloaderScene extends Phaser.Scene {
    private readonly visualLoader: VisualLoader;

    constructor() {
        super(SceneKeys.Preloader);

        this.visualLoader = new VisualLoader(this);
    }

    preload() {
        this.load.on('progress', this.visualLoader.onLoadProgress, this.visualLoader);
        this.load.on('fileprogress', this.visualLoader.onFileProgress, this.visualLoader);
        this.load.on('complete', this.visualLoader.onLoadComplete, this.visualLoader);

        this.visualLoader.createProgressBar();

        this.load.image(TextureKeys.Background.Main.Label, TextureKeys.Background.Main.Path);

        this.load.image(TextureKeys.Button.Blue.Up.Label, TextureKeys.Button.Blue.Up.Path);
        this.load.image(TextureKeys.Button.Blue.Down.Label, TextureKeys.Button.Blue.Down.Path);
        this.load.image(TextureKeys.Button.Red.Up.Label, TextureKeys.Button.Red.Up.Path);
        this.load.image(TextureKeys.Button.Red.Down.Label, TextureKeys.Button.Red.Down.Path);
        this.load.image(TextureKeys.Button.Checkbox.Unchecked.Label, TextureKeys.Button.Checkbox.Unchecked.Path);
        this.load.image(TextureKeys.Button.Checkbox.Checked.Label, TextureKeys.Button.Checkbox.Checked.Path);

        this.load.spritesheet(SpriteKeys.Player.Main.Label, SpriteKeys.Player.Main.Path, {
            frameWidth: SpriteKeys.Player.Main.Width,
            frameHeight: SpriteKeys.Player.Main.Height
        });

        this.load.spritesheet(SpriteKeys.Asteroid.Ceres.Label, SpriteKeys.Asteroid.Ceres.Path, {
            frameWidth: SpriteKeys.Asteroid.Ceres.Width,
            frameHeight: SpriteKeys.Asteroid.Ceres.Height
        });

        this.load.spritesheet(SpriteKeys.Fullscreen.Label, SpriteKeys.Fullscreen.Path, {
            frameWidth: SpriteKeys.Fullscreen.Width,
            frameHeight: SpriteKeys.Fullscreen.Height
        });

        this.load.audio(SoundKeys.SelectMenu.Label, SoundKeys.SelectMenu.Path);
        this.load.audio(SoundKeys.Select.Label, SoundKeys.Select.Path);
        this.load.audio(SoundKeys.Unselect.Label, SoundKeys.Unselect.Path);

        this.load.audioSprite(SoundKeys.Sprite.Key, SoundKeys.Sprite.JsonUrl, [
            SoundKeys.Sprite.ShipMoving.Path
        ]);

        this.load.audio(MusicKeys.Main.Label, MusicKeys.Main.Path);
        this.load.audio(MusicKeys.Secondary.Label, MusicKeys.Secondary.Path);
    }

    create() {
        Game.jukebox = new Jukebox(this, SceneKeys.Title);
        Game.jukebox.getSystemRegistry(`system_${Game.id}`);
        Game.jukebox.select(MusicKeys.Main.Label);
        Game.jukebox.playMusic();

        this.scene.start(SceneKeys.Title);
    }
}
