import {SceneKeys, TitleKeys, TextureKeys} from "../const/keys";
import Button from "../gameObjects/Button";
import {Game} from "../game";

export default class OptionsScene extends Phaser.Scene {
    private text: Phaser.GameObjects.Text | undefined;
    private musicButton: any;
    private musicText: Phaser.GameObjects.Text | undefined;
    private soundCheckbox: Phaser.GameObjects.Image | undefined;
    private soundText: Phaser.GameObjects.Text | undefined;
    private menuButton: Button | undefined;

    constructor() {
        super(SceneKeys.Options);
    }

    create () {
        const option = this;
        const scene = this.scene.scene;

        scene.cameras.main.setBackgroundColor('rgb(0,0,0,0.7)');

        this.text = this.add.text(
            window.innerWidth/2 - 80,
            window.innerHeight/2 - 200,
            TitleKeys.Options.Title,
            // @ts-ignore - size should stay int
            { fontSize: 40 }
        );

        this.musicText = this.add.text(
            window.innerWidth/2 - 90,
            window.innerHeight/2 - 100,
            TitleKeys.Options.Child.Music.Label,
            // @ts-ignore - size should stay int
            { fontSize: 24 }
        );

        this.musicButton = this.add.image(
            window.innerWidth/2 - 190,
            window.innerHeight/2 - 100,
            Game.jukebox.system.musicOn ?
                TextureKeys.Button.Checkbox.Checked.Label :
                TextureKeys.Button.Checkbox.Unchecked.Label
        ).on('pointerdown', function () {
            Game.jukebox.toggleMusic();
            option.toggleCheckboxMusic();
        }.bind(this)).setInteractive();

        this.soundText = this.add.text(
            window.innerWidth/2 - 90,
            window.innerHeight/2,
            TitleKeys.Options.Child.Sound.Label,
            // @ts-ignore - size should stay int
            { fontSize: 24 }
        );

        this.soundCheckbox = this.add.image(
            window.innerWidth/2 - 190,
            window.innerHeight/2,
            Game.jukebox.system.soundOn ?
                TextureKeys.Button.Checkbox.Checked.Label :
                TextureKeys.Button.Checkbox.Unchecked.Label
        ).on('pointerdown', function () {
            Game.jukebox.toggleSound();
            option.toggleCheckboxSound();
        }.bind(this)).setInteractive();

        if (Game.instance) {
            this.menuButton = new Button(
                this,
                window.innerWidth/2,
                window.innerHeight/2 + 200,
                TextureKeys.Button.Red.Up.Label,
                TextureKeys.Button.Red.Down.Label,
                TitleKeys.Leave,
                function () {
                    if (Game.client.playerEntities.has(Game.client.room.sessionId)) {
                        // @ts-ignore - never null
                        if (Game.client.playerEntities.get(Game.client.room.sessionId).isMoving) {
                            scene.scene.stop(SceneKeys.Options);
                            Game.event.emit('ui-toast', "Can't leave while moving!");
                            return;
                        }
                    }

                    Game.client.room.leave().then(function () {
                        Game.camera.cam.fadeOut().once('camerafadeoutcomplete', function () {
                            Game.instance = undefined;
                            Game.jukebox.stopSpriteSound();
                            scene.scene.stop(SceneKeys.Ui);
                            scene.scene.stop(SceneKeys.Options);
                            scene.scene.stop(SceneKeys.World);
                            scene.scene.start(SceneKeys.Title);
                        });
                    });
                }
            );
        } else {
            this.menuButton = new Button(
                this,
                window.innerWidth/2,
                window.innerHeight/2 + 200,
                TextureKeys.Button.Blue.Up.Label,
                TextureKeys.Button.Blue.Down.Label,
                TitleKeys.Menu,
                function () {
                    scene.scene.stop(SceneKeys.Ui);
                    scene.scene.stop(SceneKeys.Options);
                    scene.scene.stop(SceneKeys.World);
                    scene.scene.start(SceneKeys.Title);
                }
            );
        }
    }

    toggleCheckboxMusic() {
        if (Game.jukebox.system.musicOn) {
            this.musicButton.setTexture(TextureKeys.Button.Checkbox.Checked.Label);
        } else {
            this.musicButton.setTexture(TextureKeys.Button.Checkbox.Unchecked.Label);
        }
    }

    toggleCheckboxSound() {
        if (Game.jukebox.system.soundOn === false) {
            this.soundCheckbox?.setTexture(TextureKeys.Button.Checkbox.Unchecked.Label);
        } else {
            this.soundCheckbox?.setTexture(TextureKeys.Button.Checkbox.Checked.Label);
        }
    }
}
