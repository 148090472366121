import {SceneKeys, SoundKeys, TextureKeys} from '../const/keys';
import Menu from 'phaser3-rex-plugins/templates/ui/menu/Menu';

import {Game} from '../game';
import Player from '../sprites/player';
import BoardPlugin from "phaser3-rex-plugins/plugins/board-plugin.js";
import {Label} from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import UIPlugin from 'phaser3-rex-plugins/templates/ui/ui-plugin.js';

const WHEEL_Y_UP = -102;
const WHEEL_Y_DOWN = 102;

export default class WorldScene extends Phaser.Scene {
    public menu: Menu | undefined;
    private isPointerMoved: boolean | undefined;
    private rexBoard: BoardPlugin = BoardPlugin.prototype;
    private menuEntity: Menu | undefined;
    public rexUI: UIPlugin = UIPlugin.prototype;

    constructor() {
        super(SceneKeys.World);
    }

    async init() {
        // keep assigning values on init method
        this.menu = undefined;
        this.isPointerMoved = true;

        Game.init(this, SceneKeys.World);
        Game.camera.cam.fadeIn(2000);
        Game.camera.cam.setBounds(0, 0, 1350, 1150);
    }

    async create(options: any) {
        this.initWorld();
        this.scene.run(SceneKeys.Ui);
        this.createEventListeners();
        await this.connectServer();
    }

    update(time: number, delta: number) {
        super.update(time, delta);

        Game?.client?.playerEntities.forEach((player: Player) => {
            player.update(time, delta);
        });
    }

    initWorld() {
        Game.board = this.rexBoard.add.board({
            grid: {
                gridType: 'hexagonGrid',
                x: 120,
                y: 120,
                size: 60,
                // staggerAxis: 'y',
                // staggerIndex: 'odd'
            }
        });
        const tileXYArray = Game.board.fit(this.rexBoard.hexagonMap.hexagon(Game.board, 5));
        Game.map
            .setBackground(TextureKeys.Background.Main.Label)
            .drawHexagrid(tileXYArray);
    }

    async connectServer() {
        console.log('GameID:', Game.id);
        const token = this.registry.get(`token_${Game.id}`);
        const area = this.registry.get(`area_${Game.id}`);

        try {
            await Game.client.joinRoom(area, {accessToken: token}).then(() => {
                Game.client.room?.state.players.onAdd((player: any, sessionId: any) => {
                    player.scene = this;
                    player.sessionId = sessionId;
                    Game.event.emit('ui-toast', `guest_${sessionId} join the room!`);
                    const thisPlayer = new Player(player);
                    // TODO: position the camera on the main player
                    Game.camera.cam.startFollow(thisPlayer);
                    Game.camera.cam.stopFollow();
                    Game.client.playerEntities.set(sessionId, thisPlayer);

                    player.onChange(() => {
                        Game.jukebox.playSpriteSound();
                        thisPlayer.moveTo(player.x, player.y);
                    });

                    player.onRemove(() => {
                        thisPlayer.erase();
                        Game.event.emit('ui-toast', `guest_${thisPlayer.sessionId} left the room!`);
                        Game.client.playerEntities.delete(thisPlayer.sessionId);
                    });

                    thisPlayer.mobility.on('complete', () => {
                        let toStop = true;
                        Game.client.playerEntities.forEach((player: Player, key, map) => {
                            if (player.isMoving) toStop = false;
                        });

                        if (toStop) Game.jukebox.stopSpriteSound();
                    });

                    thisPlayer.on('pointerup', (pointer: Phaser.Input.Pointer) => {
                        let items = [
                            {
                                name: 'AA',
                                type: 'action'
                            },
                            {
                                name: 'BB',
                                type: 'menu',
                                children: [
                                    {name: 'BB-0', type: 'action'},
                                    {name: 'BB-1', type: 'action'},
                                ]
                            },
                        ];
                        if (pointer.leftButtonReleased()) {
                            if (this.isPointerMoving()) return;

                            if (this.menuEntity === undefined) {
                                if (this.menu) {
                                    Game.jukebox.playSound(SoundKeys.Unselect.Label);
                                    this.menu.collapse();
                                    this.menu = undefined;
                                    return;
                                }
                                Game.jukebox.playSound(SoundKeys.SelectMenu.Label);
                                this.menuEntity = Game.popMenu.CreateMenu(this, pointer.x, pointer.y, items,
                                    (button: Label) => {
                                        if (button.name === 'action') {
                                            console.log('Click ' + button.text + ' - ' + sessionId);
                                            Game.jukebox.playSound(SoundKeys.Select.Label);
                                            this.menuEntity?.collapse();
                                            this.menuEntity = undefined;
                                        } else {
                                            Game.jukebox.playSound(SoundKeys.SelectMenu.Label);
                                        }
                                    });

                                thisPlayer.hitMenu(this.menuEntity);
                            } else if (!this.menuEntity.isInTouching()) {
                                Game.jukebox.playSound(SoundKeys.Unselect.Label);
                                this.menuEntity.collapse(); // Auto collapsing if `pointerDownOutsideCollapsing: true`
                                this.menuEntity = undefined;
                            }
                        }
                    }).on('pointerdown', (pointer: any) => {
                        if (pointer.leftButtonDown()) {
                            if (this.menuEntity === undefined) {
                                thisPlayer.setTint(0x8CB9E3);
                            }
                        }
                    }).on('pointerup', () => {
                        thisPlayer.clearTint();
                    }).on('pointerout', () => {
                        thisPlayer.clearTint();
                    });
                });
            });
        } catch (e) {
            this.registry.pop(`token_${Game.id}`);
            this.registry.pop(`area_${Game.id}`);
            this.scene.start(SceneKeys.Boot);
        }
    }

    createEventListeners() {
        Game.keyboard.startListening();

        let items = [
            {
                name: 'AA',
                type: 'menu',
                children: [
                    {
                        name: 'AA-0',
                        type: 'menu',
                        children: [
                            {name: 'AA-00', type: 'action'},
                            {name: 'AA-01', type: 'action'},
                            {name: 'AA-02', type: 'action'},
                        ]
                    },
                    {
                        name: 'AA-1',
                        type: 'menu',
                        children: [
                            {name: 'AA-10', type: 'action'},
                            {name: 'AA-11', type: 'action'},
                            {name: 'AA-12', type: 'action'},
                        ]
                    },
                    {
                        name: 'AA-2',
                        type: 'menu',
                        children: [
                            {name: 'AA-20', type: 'action'},
                            {name: 'AA-21', type: 'action'},
                            {name: 'AA-22', type: 'action'},
                        ]
                    },
                ]
            },
            {
                name: 'BB',
                type: 'menu',
                children: [
                    {name: 'BB-0', type: 'action'},
                    {name: 'BB-1', type: 'action'},
                    {name: 'BB-2', type: 'action'},
                ]
            },
            {
                name: 'CC',
                type: 'menu',
                children: [
                    {name: 'CC-0', type: 'action'},
                    {name: 'CC-1', type: 'action'},
                    {name: 'CC-2', type: 'action'},
                ]
            },
        ];

        Game.board
            .setInteractive()
            .on('tiledown', (pointer: any, tileXY: any) => {
                if (pointer.rightButtonDown()) {
                    tileXY.accessToken = this.registry.get(`token_${Game.id}`);
                    Game.client.room?.send('movement', tileXY);
                    Game.event.emit('ui-chat', `Position: ${tileXY.x}, ${tileXY.y}`);
                }
            })
            // .on('tiledown', (pointer: any, tileXY: any) => {
            // })
            .on('tileup', (pointer: any, tileXY: any) => {
                // Game.event.emit('menu-position', pointer, worldXY.x, worldXY.y, items);
                if (pointer.leftButtonReleased()) {
                    if (this.isPointerMoving()) return;

                    if (this.menuEntity) {
                        Game.jukebox.playSound(SoundKeys.Unselect.Label);

                        this.menuEntity.collapse();
                        this.menuEntity = undefined;
                        return;
                    }

                    if (this.menu === undefined) {
                        Game.jukebox.playSound(SoundKeys.SelectMenu.Label);
                        let worldXY = Game.board.tileXYToWorldXY(tileXY.x, tileXY.y);
                        this.menu = Game.popMenu.CreateMenu(this, worldXY.x, worldXY.y, items,
                            (button: any) => {
                                if (button.name === 'action') {
                                    let tileXY = Game.board.worldXYToTileXY(worldXY.x, worldXY.y);
                                    Game.jukebox.playSound(SoundKeys.Select.Label);
                                    console.log('Click ' + button.text + ' ' + button.name + ' -', tileXY.x, tileXY.y);
                                    this.menu?.collapse();
                                    this.menu = undefined;
                                } else {
                                    Game.jukebox.playSound(SoundKeys.SelectMenu.Label);
                                }
                            });
                    } else if (!this.menu.isInTouching()) {
                        Game.jukebox.playSound(SoundKeys.Unselect.Label);
                        this.menu.collapse(); // Auto collapsing if `pointerDownOutsideCollapsing: true`
                        this.menu = undefined;
                    }
                }
            });

        // camera movements
        this.input.on('pointermove', (pointer: Phaser.Input.Pointer) => {
            if (!(pointer.getDuration() < 150) && pointer.leftButtonDown()) { // make sure user want to move the camera
                this.isPointerMoved = true;
                Game.camera.moveTo(pointer);
            }
        });

        // mouse scroller
        this.input.on('wheel', (
            pointer: any,
            gameObjects: Phaser.GameObjects.GameObject,
            deltaX: number,
            deltaY: number,
            deltaZ: number
        ) => {
            if (deltaY === WHEEL_Y_UP && Game.camera.cam.zoom < 4) {
                let value = Game.camera.cam.zoom + 0.1;
                Game.camera.cam.zoom = value;
                Game.camera.cam.setZoom(value);
            } else if (deltaY === WHEEL_Y_DOWN && Game.camera.cam.zoom > 1) {
                let value = Game.camera.cam.zoom - 0.1;
                Game.camera.cam.zoom = value;
                Game.camera.cam.setZoom(value);
            }
        });
    }

    private isPointerMoving() {
        if (this.isPointerMoved) {
            this.isPointerMoved = false;
            return true;
        }
        return false;
    }
}
