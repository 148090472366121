const GetValue = Phaser.Utils.Objects.GetValue;

const markPassword = function (password: string) {
    return new Array(password.length + 1).join('•');
};

const COLOR_PRIMARY = 0x537FE7;
const COLOR_LIGHT = 0xC0EEF2;
const COLOR_DARK = 0x181823;

export default class LoginBuilder {
    text: any;

    public static createLoginDialog(scene: any, config: any) {
        var username = GetValue(config, 'username', '');
        var password = GetValue(config, 'password', '');
        var title = GetValue(config, 'title', 'Welcome');
        var x = GetValue(config, 'x', 0);
        var y = GetValue(config, 'y', 0);
        var width = GetValue(config, 'width', undefined);
        var height = GetValue(config, 'height', undefined);

        // Background object
        var background = scene.rexUI.add.roundRectangle(0, 0, 10, 10, 10, COLOR_DARK);

        // Title field object
        var titleField = scene.add.text(0, 0, title, {fontSize: 20});

        // User name field object
        var userNameField = scene.rexUI.add.label({
            orientation: 'x',
            background: scene.rexUI.add.roundRectangle(0, 0, 10, 10, 10).setStrokeStyle(1, COLOR_LIGHT),
            icon: scene.add.image(0, 0, 'user'),

            text: scene.rexUI.add.canvasInput({
                width: 150, height: 36,
                style: {
                    fontSize: 20,
                    backgroundBottomY: 5,
                    backgroundHeight: 20,

                    // Solution A
                    'cursor.color': 'black',
                    'cursor.backgroundColor': 'white',
                },
                wrap: {
                    vAlign: 'center'
                },
                text: username,
            }),

            space: {top: 5, bottom: 5, left: 5, right: 5, icon: 10,}
        })

        // Password field object
        var passwordField = scene.rexUI.add.label({
            orientation: 'x',
            background: scene.rexUI.add.roundRectangle(0, 0, 10, 10, 10).setStrokeStyle(1, COLOR_LIGHT),
            icon: scene.add.image(0, 0, 'password'),

            text: scene.rexUI.add.canvasInput({
                width: 150, height: 36,
                style: {
                    fontSize: 20,
                    backgroundBottomY: 5,
                    backgroundHeight: 20,

                    // Solution A
                    'cursor.color': 'black',
                    'cursor.backgroundColor': 'white',
                },
                wrap: {
                    vAlign: 'center'
                },
                text: markPassword(password),

                onOpen(textObject: any, hiddenInputText: any) {
                    // Can't use passwordField.text because it is masked by dot
                    textObject.setInputText(password);
                },

                onUpdate(text: string, textObject: any, hiddenInputText: any) {
                    // Save password from input of hiddenEdit
                    password = text;

                    // Return masked string for textObject displaying
                    return markPassword(text);
                }
            }),

            space: {top: 5, bottom: 5, left: 5, right: 5, icon: 10,}
        })

        // Login button object
        var loginButton = scene.rexUI.add.label({
            orientation: 'x',
            background: scene.rexUI.add.roundRectangle(0, 0, 10, 10, 10, COLOR_PRIMARY),
            text: scene.add.text(0, 0, 'Login', {fontSize: 20}),
            space: {top: 8, bottom: 8, left: 8, right: 8}
        })
            .setInteractive()
            .on('pointerdown', function () {
                username = userNameField.text;
                loginDialog.emit('login', username, password);
            });

        // Dialog and its children
        var loginDialog = scene.rexUI.add.sizer({
            orientation: 'y',
            x: x,
            y: y,
            width: width,
            height: height,
        })
            .addBackground(background)
            .add(titleField, 0, 'center', {top: 10, bottom: 10, left: 10, right: 10}, false)
            .add(userNameField, 0, 'left', {bottom: 10, left: 10, right: 10}, true)
            .add(passwordField, 0, 'left', {bottom: 10, left: 10, right: 10}, true)
            .add(loginButton, 0, 'center', {bottom: 10, left: 10, right: 10}, false)
            .layout();

        return loginDialog;
    }
}
