import {Game} from "../game";

export default class KeyboardManager extends Phaser.GameObjects.GameObject {
    public startListening() {
        this.scene.input.keyboard.on('keydown',
            function (event: any) {
                // console.log(event.key, event.keyCode);

                switch (event.keyCode) {
                    case 27 : {
                        Game.layer.options();
                        break;
                    }
                    case 87 : {
                        Game.layer.ui();
                        break;
                    }
                }
            }.bind(this));
    }
}
