import { Room, Client as Cli } from 'colyseus.js';
import Player from '../sprites/player';

export default class Client extends Cli {
    public roomName: string | undefined;
    public room: Room = Room.prototype;
    public playerEntities: Map<string, Player> = new Map<string, Player>();

    constructor(endpoint: string) {
        super(endpoint);
    }

    public async joinRoom(selectedRoom: string, data: any) {
        this.roomName = selectedRoom;
        await this.connect(data);
    }

    private async connect(data: any): Promise<void> {
        if (!this.roomName) {return;}

        try {
            this.room = await this.joinOrCreate(this.roomName, data);
            console.log("Connection successful!");
        } catch (e) {
            console.error("Couldn't connect:");
        }
    }
}
