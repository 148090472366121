import {MathUtils} from "../utils/MathUtils";

export class VisualLoader {
    private readonly scene: Phaser.Scene;
    private progressBar: Phaser.GameObjects.Graphics | undefined;
    private progressBox: Phaser.GameObjects.Graphics | undefined;
    private percentText: Phaser.GameObjects.Text | undefined;
    private loadingText: Phaser.GameObjects.Text | undefined;
    private assetText: Phaser.GameObjects.Text | undefined;

    constructor(scene: Phaser.Scene) {
        this.scene = scene;
    }

    /**
     * createProgressBar
     */
    public createProgressBar() {
        const main = this.scene.cameras.main;

        this.progressBar = this.scene.add.graphics();
        this.progressBox = this.scene.add.graphics();
        this.progressBox.fillStyle(0x222222, 0.8);
        this.progressBox.fillRect(main.width / 2 - 165, main.height / 2, 320, 50);

        this.loadingText = this.scene.make.text({
            x: main.width / 2,
            y: main.height / 2 - 50,
            text: 'Loading...',
            style: {
                font: '20px monospace',
                // @ts-ignore
                fill: '#ffffff'
            }
        });
        this.loadingText.setOrigin(0.5, 0.5);

        this.percentText = this.scene.make.text({
            x: main.width / 2,
            y: main.height / 2 - 5,
            text: '0%',
            style: {
                font: '18px monospace',
                // @ts-ignore
                fill: '#ffffff'
            }
        });
        this.percentText.setOrigin(0.5, 0.5);

        this.assetText = this.scene.make.text({
            x: main.width / 2 - 10,
            y: main.height / 2 + 70,
            text: 'Loading asset: ',
            style: {
                font: '18px monospace',
                // @ts-ignore
                fill: '#ffffff'
            }
        });
        this.assetText.setOrigin(0.5, 0.5);
    }

    /**
     * onFileComplete
     */
    public onFileProgress(file: { key: string; }) {
        if (!this.assetText) { return; }
        // console.debug('file', file.key);

        this.assetText.setText('Loading asset: ' + file.key);
    }

    /**
     * onLoadComplete
     */
    public onLoadComplete(loader: any, totalComplete: number, totalFailed: number) {
        if (!this.progressBar || !this.progressBox || !this.loadingText || !this.assetText || !this.percentText) { return; }
        // console.debug('complete', totalComplete);
        // console.debug('failed', totalFailed);

        this.progressBar.destroy();
        this.progressBox.destroy();
        this.loadingText.destroy();
        this.percentText.destroy();
        this.assetText.destroy();
    }

    /**
     * onLoadProgress
     */
    public onLoadProgress(progress: number) {
        if (!this.progressBar || !this.progressBox || !this.percentText) { return; }
        // console.debug('progress', progress);
        const main = this.scene.cameras.main;
        const progressPercent = MathUtils.round(progress * 100, 0);

        this.percentText.setText(progressPercent + '%');
        this.progressBar.clear();
        this.progressBar.setDepth(30);
        this.progressBar.fillStyle(0xffffff, 1);
        this.progressBar.fillRect(main.width / 2 - 155, main.height / 2 + 10, 300 * progress, 30);
    }
}
