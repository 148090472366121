export default class CameraBase extends Phaser.Cameras.Scene2D.CameraManager
{
    public cam: Phaser.Cameras.Scene2D.Camera;
    constructor(scene: any) {
        super(scene);
        this.cam = scene.cameras.main;
    }

    public moveTo(pointer: Phaser.Input.Pointer) {
        this.cam.scrollX -= (pointer.x - pointer.prevPosition.x) / this.cam.zoom;
        this.cam.scrollY -= (pointer.y - pointer.prevPosition.y) / this.cam.zoom;
    }
}
