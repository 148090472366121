import {SoundKeys, SpriteKeys} from "../const/keys";
import BaseEntity from "./baseEntity";
import { MoveTo } from 'phaser3-rex-plugins/plugins/board-components.js';
import {Game} from "../game";
import Menu from "phaser3-rex-plugins/templates/ui/menu/Menu";

const X_NAME_POS_ADJUST = 70;
const Y_NAME_POS_ADJUST = 60;

export default class Player extends BaseEntity {
    public isMoving: boolean;
    public previousY: number;
    public previousX: number;
    public mobility: MoveTo;
    private nameText: Phaser.GameObjects.Text;
    private readonly speed: number;
    private menu: Menu | undefined;
    public sessionId: string;

    constructor({...args}) {
        // Convert to worldXY before super construct
        let worldXY = Game.board.tileXYToWorldXY(args.x, args.y);
        args.x = worldXY.x;
        args.y = worldXY.y;
        super({...args});
        this.sessionId = args.sessionId;
        this.name = args.name;
        this.previousX = args.x;
        this.previousY = args.y;
        this.speed = args.speed;
        this.isMoving = false;

        this.nameText = this.printNickname();
        this.mobility = this.addPluginMobility();

        this.createPhysics();
        this.createAnimations();
        this.createEventListeners();
    }

    private createEventListeners() {
        this.mobility.on('complete', () => {
            this.isMoving = false;
            this.anims.play('idle')
        });
    }

    public update(time: number, delta: number) {
        super.update(time, delta);

        this.updateNicknamePos();
        this.updateMenuPos();
    }

    public hitMenu(menu: any) {
        this.menu = menu;
    }

    public updateMenuPos() {
        // @ts-ignore
        this.menu?.x = this.x;
        // @ts-ignore
        this.menu?.y = this.y;
    }

    public moveTo(x: number, y: number) {
        this.isMoving = true;
        this.anims.play('move');

        let worldXY = Game.board.tileXYToWorldXY(x, y);
        this.mobility.moveTo(worldXY.x, worldXY.y);
    }

    public stopMoving() {
        this.mobility.stop();
        this.mobility.setEnable(false);
        this.anims.play('idle');
        this.stop();
    }

    public erase() {
        this.mobility.destroy();
        this.nameText.destroy(true);
        this.destroy(true);
    }

    private createPhysics() {
        this.scene.add.existing(this);
        this.setInteractive();
    }

    private createAnimations() {
        this.anims.create({
            key: 'idle',
            frames: this.anims.generateFrameNumbers(
                SpriteKeys.Player.Main.Label,
                {start: 0, end: 0}
            ),
        });

        this.anims.create({
            key: 'move',
            frames: this.anims.generateFrameNumbers(
                SpriteKeys.Player.Main.Label,
                {start: 1, end: 3}
            ),
            duration: 750,
            frameRate: 2,
            repeat: -1,
        });
    }

    private printNickname() {
        return this.scene.add.text(this.x - 70, this.y - 60, this.name, {
            fontSize: '20px',
            color: '#fff'
        });
    }

    private updateNicknamePos() {
        this.nameText.x = this.x - X_NAME_POS_ADJUST;
        this.nameText.y = this.y - Y_NAME_POS_ADJUST;
    }

    private addPluginMobility() {
        // @ts-ignore
        return this.scene.plugins.get('rexMoveTo').add(this, {
            speed: this.speed,
            rotateToTarget: true
        });
    }

    private addPluginRotation() {
        // TODO: integrate rotation plugin
    }
}
