export function request<Request, Response>(
    method: 'GET' | 'POST',
    url: string,
    content?: Request,
    callback?: (response: Response) => void,
    failCallback?: (failMessage: string) => void,
    errorCallback?: (err: any) => void) {

    const request = new XMLHttpRequest();
    request.open(method, url, true);
    request.onload = function () {
        if (this.status >= 200 && this.status < 400) {
            // Success!
            const data = JSON.parse(this.response) as Response;
            callback && callback(data);
        } else {
            // Failed!
            const data = JSON.parse(this.response);
            failCallback && failCallback(data.message);
        }
    };

    request.onerror = function (err) {
        // There was a connection error of some sort
        errorCallback && errorCallback(err);
    };
    if (method === 'POST') {
        request.setRequestHeader(
            'Content-Type',
            'application/json');

        request.setRequestHeader(
            'Accept',
            'application/json');
    }

    request.send(JSON.stringify(content));
}
