import {SceneKeys} from "../const/keys";

export default class CreditsScene extends Phaser.Scene {
    private creditsText: Phaser.GameObjects.Text | undefined;
    private madeByText: Phaser.GameObjects.Text | undefined;
    private zone: Phaser.GameObjects.Zone | undefined;
    private creditsTween: Phaser.Tweens.Tween | undefined;
    private madeByTween: Phaser.Tweens.Tween | undefined;

    constructor() {
        super(SceneKeys.Credits);
    }

    create () {
        document.body.style.backgroundColor = 'black';

        // @ts-ignore
        this.creditsText = this.add.text(0, 0, 'Credits', { fontSize: '32px', fill: '#fff' });
        // @ts-ignore
        this.madeByText = this.add.text(0, 0, 'Created By: Iliass Raihani', { fontSize: '26px', fill: '#fff' });
        this.zone = this.add.zone(window.innerWidth/2, window.innerHeight/2, window.innerWidth, window.innerHeight);

        Phaser.Display.Align.In.Center(
            this.creditsText,
            this.zone
        );

        Phaser.Display.Align.In.Center(
            this.madeByText,
            this.zone
        );

        this.madeByText.setY(1000);

        this.creditsTween = this.tweens.add({
            targets: this.creditsText,
            y: -100,
            ease: 'Power1',
            duration: 3000,
            delay: 1000,
            onComplete: function () {
                // @ts-ignore
                this.destroy;
            }
        });

        this.madeByTween = this.tweens.add({
            targets: this.madeByText,
            y: -300,
            ease: 'Power1',
            duration: 8000,
            delay: 1000,
            onComplete: function () {
                // @ts-ignore
                this.madeByTween.destroy;
                // @ts-ignore
                this.scene.start(SceneKeys.Title);
            }.bind(this)
        });
    }
}
