import UIPlugin from 'phaser3-rex-plugins/templates/ui/ui-plugin.js';
import {ColorKeys} from "../const/keys";
// import UIPlugins from 'phaser3-rex-plugins/templates/ui/ui-plugin.js';

type PhaserUI = Phaser.Scene & { rexUI: UIPlugin };

export default class ToastBuilder {

    // return toast
    static CreateToast(scene: PhaserUI, x?: number, y?: number, items?: any[], onClick?: Function) {
        return scene.rexUI.add.toast({
            x: window.innerWidth/2,
            y: 40,
            duration: {
                hold: 2000,
            },
            background: scene.rexUI.add.roundRectangle(0, 0, 2, 2, 20, ColorKeys.Dark),
            text: scene.add.text(0, 0, '', {
                fontSize: '24px'
            }),
            space: {
                left: 20,
                right: 20,
                top: 20,
                bottom: 20,
            },
        });
    }
}
