import Menu from 'phaser3-rex-plugins/templates/ui/menu/Menu';
import {Label} from "phaser3-rex-plugins/templates/ui/ui-components.js";

const COLOR_PRIMARY = 0x537FE7;
const COLOR_LIGHT = 0xC0EEF2;
const COLOR_DARK = 0x181823;

import UIPlugin from 'phaser3-rex-plugins/templates/ui/ui-plugin.js';
const EXPAND_ORIENTATION = 'y';
const EASE_ORIENTATION = 'y';

type PhaserUI = Phaser.Scene & {rexUI: UIPlugin};

export default class PopupMenuBuilder {
    public menu: Menu | undefined;

    constructor() {
        this.menu = undefined;
    }

    CreateMenu(scene: PhaserUI, x: number, y: number, items: any[], onClick: Function) {
        const config = {
            x: x,
            y: y,
            // subMenuSide: 'right',

            items: items,
            createBackgroundCallback: function (items: any) {
                return scene.rexUI.add.roundRectangle(0, 0, 2, 2, 10, COLOR_DARK);
            },

            createButtonCallback: function (item: any, i: any, items: any) {
                return scene.rexUI.add.label({
                    background: scene.rexUI.add.roundRectangle(0, 0, 2, 2, 10),
                    text: scene.add.text(0, 0, item.name, {
                        fontSize: '20px',
                    }),

                    name: item.type,

                    icon: scene.rexUI.add.roundRectangle(0, 0, 0, 0, 10, COLOR_PRIMARY),
                    space: {
                        left: 10,
                        right: 10,
                        top: 10,
                        bottom: 10,
                        icon: 10
                    }
                })
            },

            // easeIn: 500,
            easeIn: {
                duration: 500,
                orientation: EASE_ORIENTATION
            },

            // easeOut: 100,
            easeOut: {
                duration: 100,
                orientation: EXPAND_ORIENTATION
            },

            // expandEvent: 'button.over',

            pointerDownOutsideCollapsing: false,

            // space: { item: 10 }
        }

        // @ts-ignore
        const menu = scene.rexUI.add.menu(config);

        menu
            .on('button.over', function (button: any) {
                button.getElement('background').setStrokeStyle(2, COLOR_LIGHT);
            })
            .on('button.out', function (button: any) {
                button.getElement('background').setStrokeStyle();
            })
            .on('button.click', (button: Label) => {
                onClick(button);
            })
            .on('expand', (subMenu: any) => {
                // console.log('expand');
            })
            .on('popup.complete', (subMenu: any) => {
                // console.log('popup.complete');
            })
            .on('scaledown.complete', () => {
                // console.log('scaledown.complete');
            })

        return menu;
    }
}
