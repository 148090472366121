export class MathUtils {
    static round(num: number, decimals: number): number {
        const factor = Math.pow(10, decimals);
        return Math.round(num * factor) / factor;
    }
}

export class StringUtils {
    static makeId(length: number) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }
}
