import {BACKEND_URL} from "./backend";

import CameraBase from "./gameObjects/CameraBase";
import MapBuilder from "./gameObjects/MapBuilder";
import KeyboardManager from "./gameObjects/KeyboardManager";
import LayerManager from "./gameObjects/LayerManager";
import Client from "./services/Client";
import Player from "./sprites/player";
import BoardPlugin from "phaser3-rex-plugins/plugins/board-plugin";
import Board = BoardPlugin.Board;
import PopupMenu from "./gameObjects/PopupMenuBuilder";
import PopupMenuBuilder from "./gameObjects/PopupMenuBuilder";
import Jukebox from "./gameObjects/Jukebox";


export class Game {
    public static id: string;
    public static instance: Phaser.Game | undefined;
    public static map: MapBuilder;
    public static keyboard: KeyboardManager;
    public static layer: LayerManager;
    public static camera: CameraBase;
    public static client: Client;
    public static player: Player;
    public static board: Board;
    public static scene: Phaser.Scene;
    public static event: Phaser.Events.EventEmitter;
    public static popMenu: PopupMenu;
    public static jukebox: Jukebox;

    static init(scene: Phaser.Scene, key: string) {
        Game.client = new Client(BACKEND_URL);
        Game.instance = scene.game;
        Game.keyboard = new KeyboardManager(scene, key);
        Game.map = new MapBuilder(scene, key);
        Game.camera = new CameraBase(scene);
        Game.layer = new LayerManager(scene, undefined);
        Game.event = new Phaser.Events.EventEmitter();
        Game.popMenu = new PopupMenuBuilder();
    }
}
