export const SceneKeys = {
    Boot: 'boot',
    Preloader: 'preload',
    Options: 'options',
    Credits: 'credits',
    Title: 'title',
    World: 'world',
    Ui: 'ui'
}

export const ColorKeys = {
    Primary: 0x537FE7,
    Light: 0xC0EEF2,
    Dark: 0x181823
}

export const MusicKeys = {
    Main: {
        Label: 'BgMusicMain',
        Path: 'musics/ObservingTheStar.ogg',
    },
    Secondary: {
        Label: 'Swarm',
        Path: 'musics/TheSwarmApproaches.ogg',
    }
}

export const SoundKeys = {
    SelectMenu: {
        Label: 'SelectMenu',
        Path: 'sounds/mixkit-player-jumping-in-a-video-game-2043.wav',
    },
    Select: {
        Label: 'Select',
        Path: 'sounds/mixkit-bonus-earned-in-video-game-2058.wav',
    },
    Unselect: {
        Label: 'Unselect',
        Path: 'sounds/mixkit-quick-jump-arcade-game-239.wav',
    },
    Sprite: {
        Key: 'spriteSfx',
        JsonUrl: 'data/soundsprite.json',
        ShipMoving: {
            Label: 'ShipMoving',
            Path: 'sounds/ufo-hovering-retro-sci-fi-sound-effects-17701.mp3',
        },
    }
}

export const SpriteKeys = {
    Player: {
        Main: {
            Label: 'player-main',
            Path: 'sprites/player92x69.png',
            Width: 92,
            Height: 69
        },
    },

    Asteroid: {
        Ceres: {
            Label: 'ceres',
            Path: 'sprites/asteroid_brown160x160.png',
            Width: 160,
            Height: 160
        },
    },

    Fullscreen: {
        Label: 'fullscreen',
        Path: 'ui/fullscreen.png',
        Width: 63,
        Height: 64
    },
}

export const TextureKeys = {
    Background: {
        Main: {
            Label: 'bg-main',
            Path: 'backgrounds/bg_main_01.png',
        },

        Ring: {
            Label: '',
            Path: '',
        },
    },

    Button: {
        Blue: {
            Up: {
                Label: 'blueButton1',
                Path: 'ui/blue_button02.png',
            },
            Down: {
                Label: 'blueButton2',
                Path: 'ui/blue_button03.png',
            }
        },

        Red: {
            Up: {
                Label: 'redButton1',
                Path: 'ui/red_button02.png',
            },
            Down: {
                Label: 'redButton2',
                Path: 'ui/red_button03.png',
            }
        },

        Checkbox: {
            Unchecked: {
                Label: 'box',
                Path: 'ui/grey_box.png',
            },

            Checked: {
                Label: 'checkedBox',
                Path: 'ui/blue_boxCheckmark.png',
            }
        }
    }
}

export const TitleKeys = {
    Menu: 'Menu',
    Start: 'Join',
    Options: {
        Title: 'Options',
        Child: {
            Music: {
                Type: 'Checkbox',
                Label: 'Music Enabled',
            },
            Sound: {
                Type: 'Checkbox',
                Label: 'Sound Enabled',
            }
        }
    },
    Leave: 'Leave',
    Credits: 'Credits'
}
