import {ColorKeys} from "../const/keys";
import UIPlugin from "phaser3-rex-plugins/templates/ui/ui-plugin";
import ScrollablePanel = UIPlugin.ScrollablePanel;

export default class ChatBox extends Phaser.GameObjects.Container {
    scene: any;
    private panel: ScrollablePanel | undefined;

    constructor(scene:any) {
        super(scene);
        this.scene.add.existing(this);
    }

    create() {
        this.panel = this.scene.rexUI.add.scrollablePanel({
            x: 215, y: window.innerHeight - 110,
            width: 500,
            height: 200,
            mouseWheelScroller: {
                focus: true,
                speed: 0.1
            },

            scrollMode: 'v',

            panel: {
                child: this.CreatePanel(),
            },

            slider: {
                track: this.scene.rexUI.add.roundRectangle(0, 0, 20, 10, 10, ColorKeys.Dark),
                thumb: this.scene.rexUI.add.roundRectangle(0, 0, 0, 0, 13, ColorKeys.Primary),
            },
        })

        this.panel?.layout();
    }

    printText(text: string) {
        // @ts-ignore
        this?.panel?.getElement('panel')?.add(
            this.CreatePaper(
                text,
                this.scene.rexUI.add.roundRectangle(0, 0, 400, 100, 0, ColorKeys.Dark))
        )

        this.panel?.layout();
        this.panel?.scrollToBottom();
    }

    CreatePaper(content: string, background: any) {
        return this.scene.rexUI.add.label({
            orientation: 'v',
            width: background.displayWidth,
            height: background.displayHeight / 2,

            background: background,
            text: this.scene.add.text(0, 0, content),

            align: 'center'
        })
    }

    CreatePanel() {
        const panel = this.scene.rexUI.add.sizer({
            orientation: 'v',
            space: { item: 5, top: 5, bottom: 5, right: 0, left: 10 }
        })

        let contentList = ['Welcome to Obvius Online!'];
        for (let i = 0, cnt = contentList.length; i < cnt; i++) {
            panel
                .add(
                    this.CreatePaper(
                        contentList[i],
                        this.scene.rexUI.add.roundRectangle(0, 0, 400, 100, 0, ColorKeys.Dark))
                )
        }

        return panel;
    }
}
