import {SceneKeys} from "../const/keys";
import {Game} from "../game";
import ToastBuilder from "../gameObjects/ToastBuilder";
import ChatBox from "../gameObjects/ChatBox";
import UIPlugin from "phaser3-rex-plugins/templates/ui/ui-plugin";
import Toast = UIPlugin.Toast;

export default class UiScene extends Phaser.Scene {
    private chatBox: ChatBox = ChatBox.prototype;
    private toast: Toast | undefined;
    public rexUI: UIPlugin = UIPlugin.prototype;

    constructor() {
        super(SceneKeys.Ui);
    }

    create() {
        this.chatBox = new ChatBox(this);
        this.chatBox.create();
        this.toast = ToastBuilder.CreateToast(this);

        this.addEventListeners();
    }

    private addEventListeners() {
        Game.event.on('ui-chat', (text: string) => {
            this.chatBox.printText(text);
        }).on('ui-toast', (text: string) => {
            this.toast?.showMessage(text);
        });
    }

}
