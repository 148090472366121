import {SceneKeys} from "../const/keys";
import LoginBuilder from "../gameObjects/LoginBuilder";
import {request} from "../services/httpClient";
import {BACKEND_HTTP_URL} from "../backend";
import {StringUtils} from "../utils/MathUtils";
import {Game} from "../game";
import System from "../services/system";

export default class BootScene extends Phaser.Scene {
    constructor() {
        super(SceneKeys.Boot);
    }

    preload() {
        this.load.image('user', 'https://raw.githubusercontent.com/rexrainbow/phaser3-rex-notes/master/assets/images/person.png');
        this.load.image('password', 'https://raw.githubusercontent.com/rexrainbow/phaser3-rex-notes/master/assets/images/key.png');
    }

    create() {
        this.initGameSystem();
        this.fixGameSize();
        this.popLogin();
    }

    private initGameSystem()
    {
        Game.id = StringUtils.makeId(5);
        const system = new System();
        this.registry.set(`system_${Game.id}`, system);
    }

    private fixGameSize()
    {
        this.sys.game.scale.baseSize.setSize(1350, 1150);
        this.scale.on('resize', () => {
            this.sys.game.scale.baseSize.setSize(1350, 1150);
        });
    }

    private popLogin()
    {
        const print = this.add.text(window.innerWidth / 2 - 100, window.innerHeight / 2 + 110, '');

        return LoginBuilder.createLoginDialog(this, {
            x: window.innerWidth / 2,
            y: window.innerHeight / 2,
            title: 'Authentication',
            username: 'abc',
            password: '123',
        })
            .on('login', async (username: string, password: string) => {
                request(
                    'POST',
                    BACKEND_HTTP_URL + '/login',
                    { username: username, password: password },
                    (data: any) => {
                        this.registry.set(`token_${Game.id}`, data.accessToken);
                        this.registry.set(`area_${Game.id}`, data.area);
                        this.scene.start(SceneKeys.Preloader);
                    }, (msg: string) => {
                        print.text = msg;
                    });
            })
            //.drawBounds(this.add.graphics(), 0xff0000);
            .popUp(500);
    }
}
