import {lineStyle} from "../const/config";
import {Game} from '../game';


export default class MapBuilder extends Phaser.GameObjects.GameObject {
    constructor(scene: Phaser.Scene, key: string) {
        super(scene, key);
    }

    public drawHexagrid(tileXYArray: { [x: string]: any; }) {
        const graphics = this.scene.add.graphics(lineStyle);

        let tileXY, worldXY;

        for (let i in tileXYArray) {
            tileXY = tileXYArray[i];
            graphics.strokePoints(Game.board.getGridPoints(tileXY.x, tileXY.y, true), true);
            worldXY = Game.board.tileXYToWorldXY(tileXY.x, tileXY.y);
            this.scene.add.text(worldXY.x, worldXY.y, `${tileXY.x},${tileXY.y}`).setOrigin(0.5);
        }

        return this;
    }

    public setBackground(label: string) {
        this.scene.add
            .image(0, 0, label)
            .setOrigin(0)
            .setScale(3)
            .setScrollFactor(0);

        return this;
    }
}
