import {SoundKeys} from "../const/keys";

export default class Jukebox extends Phaser.GameObjects.GameObject {
    public sfx: Map<string, Phaser.Sound.BaseSound>;
    public music: Map<string, Phaser.Sound.BaseSound>;
    public system: any;
    private _current: string;
    private _soundVolume: number;
    private _musicVolume: number;
    public marchSound: Phaser.Sound.HTML5AudioSound | Phaser.Sound.WebAudioSound;

    constructor(scene: Phaser.Scene, key: string) {
        super(scene, key);

        this._current = '';
        this.sfx = new Map<string, Phaser.Sound.BaseSound>();
        this.music = new Map<string, Phaser.Sound.BaseSound>();
        this._soundVolume = 0.1;
        this._musicVolume = 0.5;

        this.marchSound = this.scene.sound.addAudioSprite(SoundKeys.Sprite.Key);

        this.scene.add.existing(this);
    }

    public getSystemRegistry(key: string) {
        this.system = this.scene.registry.get(key);
    }

    public setSoundVolume(value: number) {
        this._soundVolume = value;
    }

    public setMusicVolume(value: number) {
        this._musicVolume = value;
    }

    public playSpriteSound() {
        if (!this.system.soundOn) return;
        if (this.marchSound.isPlaying) return;

        this.marchSound?.play();
        this.marchSound?.setMute(false);
    }

    public stopSpriteSound() {
        this.marchSound?.stop();
        this.marchSound?.setMute(true); // prevent multi-session shadow playing
    }

    public addSound(soundLabel: string, config: any = undefined) {
        let sound = undefined;

        if (config) {
            sound = this.scene.sound.add(soundLabel, config);
        } else {
            sound = this.scene.sound.add(soundLabel, {volume: this._soundVolume});
        }

        this.sfx.set(soundLabel, sound);
    }

    public addMusic(musicLabel: string) {
        let music = this.scene.sound.add(musicLabel, {volume: this._musicVolume, loop: true});
        this.music.set(musicLabel, music);
    }

    public addManySounds(soundLabels: string[]) {
        soundLabels.every((soundLabel: string) => {
            this.addSound(soundLabel);
        });
    }

    public addManyMusics(musicLabels: string[]) {
        musicLabels.every((musicLabel: string) => {
            this.addMusic(musicLabel);
        });
    }

    public playSound(soundLabel: string) {
        if (!this.system.soundOn) return;

        if (!this.sfx.has(soundLabel)) this.addSound(soundLabel);

        this.sfx.get(soundLabel)?.play();
    }

    public stopSound(soundLabel: string) {
        if (!this.system.soundOn) return;

        if (!this.sfx.has(soundLabel)) this.addSound(soundLabel);

        this.sfx.get(soundLabel)?.stop();
    }

    public select(musicLabel: string) {
        this._current = musicLabel;
    }

    public playMusic() {
        if (!this.system.musicOn || this.system.bgMusicPlaying) return;

        if (!this.music.has(this._current)) this.addMusic(this._current);

        this.music.get(this._current)?.play();
        this.system.bgMusicPlaying = true;
    }

    public stopMusic() {
        if (!this.system.musicOn && !this.system.bgMusicPlaying) return;

        this.music.get(this._current)?.stop();

        this.system.bgMusicPlaying = false;
    }

    public toggleMusic() {
        this.system.musicOn = !this.system.musicOn;

        if (this.system.musicOn) {
            this.playMusic();
        } else {
            this.stopMusic();
        }
    }

    public toggleSound() {
        this.system.soundOn = !this.system.soundOn;

        if (this.marchSound.isPlaying) {
            this.marchSound?.stop();
            this.marchSound?.setMute(true);
        }
    }
}
