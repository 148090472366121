import {SceneKeys} from "../const/keys";
import {Game} from "../game";

export default class LayerManager extends Phaser.GameObjects.GameObject {
    private plugin: Phaser.Scenes.ScenePlugin;

    constructor(scene: any, children: any) {
        super(scene, children);
        this.plugin = scene.scene;
    }

    public options() {
        if (this.plugin.isActive(SceneKeys.Options)) {
            this.plugin.sleep(SceneKeys.Options);
        } else {
            this.plugin.run(SceneKeys.Options);
            this.plugin.bringToTop(SceneKeys.Options);
        }
    }

    public ui() {
        if (this.plugin.isActive(SceneKeys.Ui)) {
            this.plugin.sleep(SceneKeys.Ui);
        } else {
            this.plugin.run(SceneKeys.Ui);
            this.plugin.bringToTop(SceneKeys.Ui);
        }
    }
}
