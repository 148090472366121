export const config = {
    type: Phaser.AUTO,
    width: window.innerWidth,
    height: window.innerHeight,
    scale: {
        mode: Phaser.Scale.RESIZE,
        autoCenter: Phaser.Scale.CENTER_BOTH,
    },
    backgroundColor: '#14182e',
}

export const colors = {
    primary: '#537FE7',
    dark: '#181823',
    light: '#C0EEF2',
    extraLight: '#E9F8F9',
    aqua: '#62f6ff',
    black: '#000',
    gold: '#fed141',
    white: '#fff',
    tileAttack: 0x6F1E51,
    tileMovementActive: 0x358F55,
    tileMovementPassive: 16777215,
};

export const fonts = {
    family: 'Futura, system-ui, sans-serif',
    styles: {
        normal: { fontFamily: 'Kenney Pixel', fontSize: 30 },
    },
};

export const hexColors = {
    darkGray: 0x222222,
    red: 0xff2200,
    white: 0xffffff,
};

export const lineStyle = {
    lineStyle: {
        width: 1,
        color: 0xffffff,
        alpha: 1
    }
}
