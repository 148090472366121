export default class Button extends Phaser.GameObjects.Container {
    button: Phaser.GameObjects.GameObject;
    scene: any;
    text: any;
    private callback: any;

    constructor(
        scene: Phaser.Scene,
        x: number,
        y: number,
        key1: string,
        key2: string,
        text: string,
        callback: Function
    ) {
        super(scene);
        this.scene = scene;
        this.x = x;
        this.y = y;
        this.callback = callback;

        this.button = this.scene.add.sprite(0, 0, key1).setInteractive();
        this.text = this.scene.add.text(0, 0, text, {fontSize: '32px', fill: '#fff'});
        Phaser.Display.Align.In.Center(this.text, this.button);

        this.add(this.button);
        this.add(this.text);

        this.button.on('pointerup', this.callback.bind(this));

        this.button.on('pointerover', function () {
            // @ts-ignore: 'this' implicitly has type 'any' because it does not have a type annotation.
            this.button.setTexture(key2);
        }.bind(this));

        this.button.on('pointerout', function () {
            // @ts-ignore: 'this' implicitly has type 'any' because it does not have a type annotation.
            this.button.setTexture(key1);
        }.bind(this));

        this.scene.add.existing(this);
    }
}
