import {SceneKeys, TextureKeys, TitleKeys, MusicKeys} from "../const/keys";
import Button from "../gameObjects/Button";

export default class TitleScene extends Phaser.Scene {
    private startButton: Button | undefined;
    private optionsButton: Button | undefined;

    constructor() {
        super(SceneKeys.Title);
    }

    create() {
        const scene = this.scene.scene;

        this.add.image(
            0,
            0,
            TextureKeys.Background.Main.Label
        )
            .setOrigin(0)
            .setScale(3)
            .setScrollFactor(0);

        // hypothetical upper button
        //  window.innerWidth/2 - 15,
        //  window.innerHeight/2 - 100,


        // Start
        this.startButton = new Button(
            this,
            window.innerWidth / 2 - 15,
            window.innerHeight / 2 + 100,
            TextureKeys.Button.Blue.Up.Label,
            TextureKeys.Button.Blue.Down.Label,
            TitleKeys.Start,
            function () {
                scene.scene.start(SceneKeys.World);
            });

        // Options
        this.optionsButton = new Button(
            this,
            window.innerWidth / 2 - 15,
            window.innerHeight / 2 + 200,
            TextureKeys.Button.Blue.Up.Label,
            TextureKeys.Button.Blue.Down.Label,
            TitleKeys.Options.Title,
            function () {
                scene.scene.start(SceneKeys.Options);
            });
    }

    centerButton(gameObject: Phaser.GameObjects.GameObject, offset = 0) {
        Phaser.Display.Align.In.Center(
            gameObject,
            this.add.zone(window.innerWidth / 2, window.innerHeight / 2 - offset * 100, window.innerWidth, window.innerHeight)
        );
    }

    centerButtonText(gameText: string, gameButton: Phaser.GameObjects.GameObject) {
        Phaser.Display.Align.In.Center(
            // @ts-ignore - ask for game object instead of string
            gameText,
            gameButton
        );
    }
};
